import { defineStore } from "pinia";
import {
  FILTER_FIELDS_SERVICE,
  HEALTH_PLAN,
  MEDICAL_PROCEDURE,
  SPECIALITY,
} from "@/constants";
import { query } from "js-query-builder";
import { useManagerApi } from "@/api";
import { usePatientStore, useSchedulesStore } from "@/stores";
import { useStorage } from "@vueuse/core";

const createDefaultFilters = () => ({
  client_id: null,
  attendance_type: null,
  health_plan: null,
  health_plan_category: null,
  service_id: null,
  specialities: [],
  medical_procedures: [],
  patient_info: {
    birth_date: null,
    sex: null,
    weight: null,
    height: null,
  },
  scheduleForAnother: false,
  appointmentDate: null,
});

const createDefaultAddingService = () => ({
  service_id: null,
  speciality: null,
  medical_procedure: null,
  professional: null,
});

export const useScheduleFiltersStore = defineStore({
  id: "scheduleFiltersStore",
  state: () => ({
    filters: useStorage(
      "schedule_filters",
      createDefaultFilters(),
      localStorage,
      {
        mergeDefaults: true,
      }
    ),
    addingService: useStorage(
      "schedules_adding_service",
      createDefaultAddingService(),
      localStorage,
      {
        mergeDefaults: true,
      }
    ),
    showMedicalGuideAlert: false,
    showAdditionalInfoForm: false,
    showMedicalProcedureNotFoundAlert: false,
    showProfessionalsNotFoundAlert: false,
    loadingProfessionals: false,
    tempServiceToAdd: null,
    loadingFilterRules: false,
    selectedAvailableSlots: {},
    hydrated: false,
  }),
  actions: {
    async addServiceToFilter(
      service,
      professional = null,
      onlyProfessionals = false
    ) {
      this.showMedicalGuideAlert = false;
      this.showAdditionalInfoForm = false;
      this.showMedicalProcedureNotFoundAlert = false;

      this.tempServiceToAdd = {
        ...service,
        professional,
      };

      this.loadingFilterRules = true;

      try {
        const urlBuilder = query("nick-agenda/search-service-rules");

        if (service.type === MEDICAL_PROCEDURE) {
          urlBuilder.filter(FILTER_FIELDS_SERVICE.exam, service.value.id);

          if (this.filters.attendance_type === HEALTH_PLAN) {
            urlBuilder.filter("health_plan_id", this.filters.health_plan.id);
          }
        }

        if (service.type === SPECIALITY) {
          urlBuilder.filter(
            FILTER_FIELDS_SERVICE.consultation,
            service.value.id
          );
        }

        const { data } = await useManagerApi().get(urlBuilder.build());

        const responseRules = data.data;
        const { patient_slot_rules } = responseRules;

        this.tempServiceToAdd.setting = responseRules;

        if (false === onlyProfessionals) {
          this.showMedicalGuideAlert = await this.checkIfMedicalGuideIsRequired(
            service.type,
            this.filters.attendance_type
          );
        }

        if (patient_slot_rules?.length) {
          if (false === this.filters.scheduleForAnother) {
            this.fillPatientInfoFilters();
          }

          this.showAdditionalInfoForm = patient_slot_rules.some(
            (ruleKey) => null === this.filters.patient_info[ruleKey]
          );
        }

        if (
          false === this.showMedicalGuideAlert &&
          false === this.showAdditionalInfoForm
        ) {
          if (false === onlyProfessionals) {
            this.concludeAddServiceToFilter();
          }

          this.loadingFilterRules = false;

          return true;
        }
      } catch (error) {
        this.loadingFilterRules = false;

        // TODO: Esse alerta foi depreciado. A principio não haverá mais o aviso quando não encontra configuração de procedimento
        // e será sempre pedido o anexo de guia quando retornar vazio. Remover a modal desse aviso (showMedicalProcedureNotFoundAlert).
        if (error?.response.status === 404) {
          this.showMedicalProcedureNotFoundAlert = true;
        }
      }

      this.loadingFilterRules = false;

      return false;
    },

    fillPatientInfoFilters() {
      const patientStore = usePatientStore();

      if (null === this.filters.patient_info.sex) {
        this.filters.patient_info.sex = patientStore.patient?.sex || null;
      }

      if (null === this.filters.patient_info.birth_date) {
        this.filters.patient_info.birth_date =
          patientStore.patient?.birthday_formatted || null;
      }
    },

    concludeAddServiceToFilter() {
      if (
        true === this.showMedicalGuideAlert ||
        true === this.showAdditionalInfoForm ||
        true === this.showMedicalProcedureNotFoundAlert
      ) {
        return false;
      }

      if (this.tempServiceToAdd.type === MEDICAL_PROCEDURE) {
        this._addMedicalProcedure();
      }

      if (this.tempServiceToAdd.type === SPECIALITY) {
        this._addSpeciality();
      }

      this.filters.service_id = null;

      this.tempServiceToAdd = null;

      this.addingService = {
        service_id: null,
        speciality: null,
        medical_procedure: null,
        professional: null,
      };

      return true;
    },

    async checkIfMedicalGuideIsRequired(serviceType, attendanceType) {
      const schedulesStore = useSchedulesStore();
      const rescheduleId = schedulesStore.toReschedule
        ? schedulesStore.toReschedule.id
        : null;

      const { data } = await useManagerApi().post(
        "nick-agenda/check-if-medical-guide-is-required",
        {
          reschedule_id: rescheduleId,
          service_type: serviceType,
          attendance_type: attendanceType,
        }
      );

      return data.is_medical_guide_required;
    },

    removeSpeciality(speciality) {
      this.filters.specialities = this.filters.specialities.filter(
        (s) => s.id !== speciality.id
      );
    },

    removeMedicalProcedure(medicalProcedure) {
      this.filters.medical_procedures = this.filters.medical_procedures.filter(
        (mp) => mp.id !== medicalProcedure.id
      );
    },

    dehydrate() {
      this.$reset();

      this.filters = createDefaultFilters();
      this.addingService = createDefaultAddingService();
    },

    setFilters(filters) {
      this.filters = {
        ...createDefaultFilters(),
        ...filters,
      };
    },

    setPatientInfo(patientInfo) {
      this.filters.patient_info = {
        ...this.filters.patient_info,
        ...patientInfo,
      };
    },

    clearListFilters() {
      this.filters.medical_procedures = [];
      this.filters.specialities = [];
      this.filters.service_id = null;
    },

    clearSelectedAvailableSlots() {
      this.selectedAvailableSlots = {};
    },

    _addMedicalProcedure() {
      if (!this.filters.medical_procedures) {
        this.filters.medical_procedures = [];
      }

      const {
        value: medicalProcedure,
        professional,
        setting,
      } = this.tempServiceToAdd;

      const alreadyIncluded = this.filters.medical_procedures.some(
        (filterMedicalProcedure) =>
          filterMedicalProcedure.id === medicalProcedure.id
      );

      if (!alreadyIncluded) {
        this.filters.medical_procedures.push({
          ...medicalProcedure,
          professional: professional,
          setting: setting,
        });
      }
    },

    _addSpeciality() {
      if (!this.filters.specialities) {
        this.filters.specialities = [];
      }

      const {
        value: speciality,
        professional,
        setting,
      } = this.tempServiceToAdd;

      const alreadyIncluded = this.filters.specialities.some(
        (filterSpeciality) => filterSpeciality.id === speciality.id
      );

      if (!alreadyIncluded) {
        this.filters.specialities.push({
          ...speciality,
          professional: professional,
          setting: setting,
        });
      }
    },
  },
});
