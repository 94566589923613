import posthog from "posthog-js";
import { inject } from "vue";

export function createPosthog(app) {
  const posthogToken = import.meta.env.VITE_APP_POSTHOG_TOKEN;

  posthog.init(posthogToken, {
    api_host: "https://us.i.posthog.com",
    // Only create a Posthog user profile when we identify the user (log in).
    person_profiles: "identified_only",
    // Posthog can't automatically capture SPA pageviews so don't try. We'll configure Vue Router later.
    // See: https://posthog.com/docs/libraries/vue-js#capturing-pageviews
    capture_pageview: false,
    loaded: function (ph) {
      if (import.meta.env.DEV) {
        // Log debugging information to the console.
        posthog.debug(true);
      }

      // Only capture Posthog data in production.
      // See: https://posthog.com/tutorials/multiple-environments#opt-out-of-capturing-on-initialization
      if (!import.meta.env.PROD) {
        ph.opt_out_capturing();
        ph.set_config({ disable_session_recording: true });
      }
    },
  });

  app.provide(posthogContextKey, posthog);
}

export const posthogContextKey = Symbol("PosthogContext");

export function usePosthog() {
  const posthog = inject(posthogContextKey);

  if (!posthog) {
    throw new Error("No Posthog found.");
  }

  return posthog;
}
