import { defineStore } from "pinia";
import { useManagerApi } from "@/api";
import { query } from "js-query-builder";

export const useLoginWithCodeStore = defineStore({
  id: "loginWithCodeStore",

  state: () => ({
    loading: null,
    cpf: null,
    contacts: [],
    selectedContactIndex: null,
  }),

  actions: {
    async getAccessContacts() {
      this.loading = true;

      const urlBuilder = query("nick-patients/access-contacts");

      urlBuilder.param("cpf", this.cpf);

      try {
        const { data } = await useManagerApi().get(urlBuilder.build());

        this.contacts = data.contacts;
      } catch (error) {
        this.error = error;
        this.loading = false;
        throw error;
      } finally {
        this.loading = false;
      }
    },
    async sendAuthenticationCodeToContact(contactIndex) {
      this.loading = true;

      try {
        await useManagerApi().post("nick-patients/send-authentication-code", {
          cpf: this.cpf,
          contact_index: contactIndex,
        });
      } catch (error) {
        this.error = error;
        this.loading = false;
        throw error;
      } finally {
        this.loading = false;
      }
    },
    async checkAuthenticationCode(code) {
      this.loading = true;

      try {
        await useManagerApi().post("nick-patients/check-authentication-code", {
          cpf: this.cpf,
          code: code,
        });
      } catch (error) {
        this.error = error;
        this.loading = false;
        throw error;
      } finally {
        this.loading = false;
      }
    },
  },
});
